import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MuiCard, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { TokenResponse, useGoogleLogin } from '@react-oauth/google'
import * as Sentry from '@sentry/nextjs'
import { Field, Form, Formik, FormikProps } from 'formik'
import { EyeOffOutline, EyeOutline, Google } from 'mdi-material-ui'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import { MouseEvent, ReactNode, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/@core/hooks/redux'
import BlankLayout from 'src/@core/layouts/BlankLayout'
import themeConfig from 'src/configs/themeConfig'
import LoginSchema from 'src/forms/validationSchemas/login'
import { useGoogleLoginCreateMutation, useLazyCoreUsersGetUserInfoQuery } from 'src/store/api/coreApi'
import { CLIENT_ID, userLogin } from 'src/store/auth/authAction'
import FooterIllustrationsV1 from 'src/views/pages/auth/FooterIllustration'

const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '28rem' }
}))

interface userLogin {
  username: string
  password: string
}

const LoginPage = () => {
  const dispatch = useAppDispatch()
  const { loading } = useSelector(state => (state as any).auth)
  const router = useRouter()
  const formikRef = useRef<any>()
  const [getMoodusToken, { data }] = useGoogleLoginCreateMutation()
  const { t } = useTranslation()

  const [getUser, { data: userInfoResp }] = useLazyCoreUsersGetUserInfoQuery()

  const onSubmitForm = ({ username, password }: userLogin, { setErrors }: FormikProps<any>) => {
    try {
      dispatch(userLogin({ username, password }) as any).then((e: any) => {
        if(e.payload?.is_deleted){
          setErrors({ username: t('Account has been deactivated. Please contact admin.') })
          return;
        }
        if (e.payload?.access_token) {
          // router.push('/give/transactions/')
          getUser({})
        } else {
          setErrors({ password: t('UsernameOrPasswordWrong') })
        }
      })
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    }
  }

  useEffect(() => {

    //redirect user to give_plus/give by active apps
    if (userInfoResp) {
      if (userInfoResp?.client?.active_apps?.includes(2)) {
        router.push('/givePlus/transactions/')
        
        return;
      }
      router.push('/give/transactions/')
    }
  }, [userInfoResp])

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const onResetPassword = () => {
    router.push('/password-reset')
  }

  const onSuccessGoogleLogin = (tokenResponse: TokenResponse) => {
    if (tokenResponse.access_token) {
      getMoodusToken({ google: { access_token: tokenResponse.access_token, client_id: CLIENT_ID as string } })
    }
  }

  const login = useGoogleLogin({
    onSuccess: tokenResponse => onSuccessGoogleLogin(tokenResponse),
    hosted_domain: 'moodus.com'
  })

  useEffect(() => {
    if (data) {
      ; (async () => {
        localStorage.setItem('userToken', (data as any).access_token)
        await new Promise(resolve => setTimeout(resolve, 100))
        router.push('/give/transactions/')
      })()
    }
  }, [data])

  return (
    <>
      <Box className='content-center'>
        <Card sx={{ zIndex: 1 }}>
          <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>
            <Box sx={{ mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img alt='moodus' src='https://cms.moodus.com/uploads/Moodus_Logo_8bee48e242.svg' />
            </Box>
            <Box sx={{ mb: 6 }}>
              <Typography variant='h5' sx={{ fontWeight: 600, marginBottom: 1.5 }}>
                {t('Welcome to')} {themeConfig.templateName}!
              </Typography>
              <Typography variant='body2'>{t('PleaseSignInToYourAccount')}</Typography>
            </Box>
            <Formik
              initialValues={{
                username: '',
                password: '',
                showPassword: false
              }}
              innerRef={formikRef}
              onSubmit={onSubmitForm as any}
              validationSchema={LoginSchema}
              enableReinitialize
            >
              {({ values, setFieldValue, submitForm, errors }: FormikProps<any>) => (
                <Form>
                  <Field
                    as={TextField}
                    autoFocus
                    fullWidth
                    error={errors.username}
                    helperText={errors.username}
                    disabled={loading}
                    name='username'
                    label={t('Username')}
                    sx={{ marginBottom: 4 }}
                  />
                  <FormControl fullWidth>
                    <InputLabel htmlFor='auth-login-password'>{t('Password')}</InputLabel>
                    <Field
                      as={OutlinedInput}
                      label={t('Password')}
                      name='password'
                      error={errors.password}
                      helperText={errors.password}
                      disabled={loading}
                      type={values.showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            edge='end'
                            onClick={() => {
                              setFieldValue('showPassword', !values.showPassword)
                            }}
                            onMouseDown={handleMouseDownPassword}
                            aria-label='toggle password visibility'
                          >
                            {values.showPassword ? <EyeOutline /> : <EyeOffOutline />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Box
                    sx={{
                      mb: 4,
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      justifyContent: 'center'
                    }}
                  >
                    {errors.password ? (
                      <Typography variant='body2' color={'error'} textAlign={'center'}>
                        {t('UsernameOrPasswordWrong')}
                      </Typography>
                    ) : null}
                  </Box>
                  <Button
                    fullWidth
                    size='large'
                    variant='contained'
                    sx={{ marginBottom: 2 }}
                    onClick={submitForm}
                    disabled={loading}
                  >
                    {t('Login')}
                  </Button>
                  <Button
                    onClick={() => login()}
                    variant='contained'
                    fullWidth
                    color='info'
                    startIcon={<Google />}
                    sx={{ marginBottom: 2 }}
                  >
                    {t('SignInWithMoodusGoogleAccount')}
                  </Button>
                  <Button
                    fullWidth
                    size='large'
                    color='error'
                    variant='text'
                    sx={{ marginBottom: 7 }}
                    onClick={onResetPassword}
                    disabled={loading}
                  >
                    {t('ResetPassword')}
                  </Button>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
        <FooterIllustrationsV1 />
      </Box>
    </>
  )
}
LoginPage.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

export async function getStaticProps(context: any) {
  // extract the locale identifier from the URL
  const { locale } = context

  return {
    props: {
      // pass the translation props to the page component
      ...(await serverSideTranslations(locale))
    }
  }
}

export default LoginPage
